import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forwardMessage } from '../../../../../constants';
import store from '../../../../../redux/store';
import Alert from './Alert';
import ChatUsers from './ChatUsers';
import Contact from './Contact';
import { asyncSearchByContactForward } from '../../../../../redux/whatsapp/actions';
import Icon from '../Icon';

function ForwardList(props) {
  let withBody = props.withBody
  const dispatch = useDispatch()
  let forwarderMessage = store.getState().whatsapp?.forwarderMessage || null;
  let vcard = store.getState().whatsapp?.vcard || null;
  const chatsArray = useSelector((state) => state.chat.data)
  const [chats, setChats] = useState(props.chats)
  const setId = (ml) => {
    // let id = ml;
    dispatch({ type: "CHAT_ID", payload: ml });
  };
  function handleSetUser(contact, id) {
    setId(null);
    dispatch({ type: "OPEN-CHAT", payload: null })
    setId(contact);
    dispatch({ type: "READ_CHAT", id: contact.id })
    forwardMessage(forwarderMessage, contact, withBody)
    setId(contact);
    props.setWithBody(true)
  }
  const sendVcard = (contact) => {
    setId(null);
    dispatch({ type: "OPEN-CHAT", payload: null })
    setId(contact);
    dispatch({ type: "READ_CHAT", id: contact.id })
    setId(contact);
    let local_message_id = Math.random() * 1000;
    let cid = contact?.id;
    let a = vcard;
    let obj = {
      body: a,
      sender_employee_user_id: JSON.parse(localStorage.getItem("user")).id,
      fromMe: 1,
      ack: "sent",
      sent_at: new Date().toISOString(),
      timestamp: Math.floor(Date.now() / 1000),
      type: "vcard",
      vcard: a,
      cid: contact?.id,
      local_message_id: local_message_id,
      chat_id: contact?.id,
      quotedMsg: null
    };
    let objs = {
      whatsapp_chat_id: `${contact?.id}`,
      body: a,
      vcard: a,
      media: null,
      type: "vcard",
      // updated_at: dd.toISOString(),
      msg_id_from_whatsapp: null,
    };

    let groubObj = {
      whatsapp_chat_id: `${contact?.id}`,
      body: a,
      vcard: a,
      media: null,
      mentions: [],
      type: "vcard",
      // updated_at: dd.toISOString(),
      msg_id_from_whatsapp: null,
    };


    dispatch({
      type: "CHANGE_IN_MESSAGES",
      payload: {
        type: "SEND_TEXT",
        payload: obj,
        isGroup: contact?.isGroup === 1,
        pay: contact?.isGroup === 1 ? groubObj : objs,
        local_message_id: local_message_id,
        cid: cid
      }
    });

    dispatch({
      type: "SEND_TEXT_SAGA",

      isGroup: contact?.isGroup === 1,
      pay: contact?.isGroup === 1 ? groubObj : objs,
      local_message_id: local_message_id,
      cid: cid,
    });
    dispatch({ type: "READ_CHAT", id: contact.id })
    setId(contact);
    props.closeSidebar()
  }
  const openAdmin = () => {

    if (props.chatss.length > 0) {
      if (vcard) {
        sendVcard(props.chatss.filter((s) => s.is_admin === 1)[0])
      }
      else
        handleSetUser(props.chatss.filter((s) => s.is_admin === 1)[0], props.chatss.filter((s) => s.is_admin === 1)[0].id);
    }
  }
  const [user, setUser] = useState([]);
  // const [open, setOpen] = useState();
  const showModal = (s, isAdmin) => {
    if (user.filter((chat) => chat.id === s.id).length > 0) {
      setUser(user.filter((chat) => chat.id !== s.id))
    }
    else {
      setUser([...user, s])
    }
  }
  const forward = () => {
    user.forEach((chat) => {
      // if (chat.is_admin === 1) {
      //   openAdmin()
      // }
      // else
      if (vcard) {
        sendVcard(chat)
      }
      else
        handleSetUser(chat, chat?.id)
    })

  }
  const [value, setValue] = useState("")
  return (
    <>
      <div className="search-wrapper">
        <div className="search-icons">
          {<Icon id="search" className="search-icon" />}
          <button className="search__back-btn">
            <Icon id="back" />
          </button>
        </div>
        <input value={value} onChange={async (e) => {
          setValue(e.target.value)
          let varchats = await asyncSearchByContactForward(e.target.value)
          setChats(varchats)
        }} className="search" placeholder="Search..." />
      </div>
      {
        user.length > 0 && <div className='forward-button' onClick={() => { forward() }}>
          Forward
        </div>
      }
      {<ChatUsers forwarderMessage={forwarderMessage} forward={() => { ; }} chats={chatsArray} />}
      <div className="sidebar__contacts" >
        {props.chats.length > 0 && <Alert noAdmin={false} noGroup={true} chat={props.chatss.filter((s) => s.is_admin === 1)[0]} name={props.chatss.filter((s) => s.is_admin === 1)[0]?.whatsapp_contact?.name ? props.chatss.filter((s) => s.is_admin === 1)[0]?.whatsapp_contact?.name : props.chatss.filter((s) => s.is_admin === 1)[0]?.whatsapp_contact?.number && ("+" + props.chatss.filter((s) => s.is_admin === 1)[0]?.whatsapp_contact?.number)} new={0}
          onClick={() => showModal(props.chatss.filter((s) => s.is_admin === 1)[0], true)} />}
        {chats.map((contact, index) => {
          return (
            <Contact
              onClick={() => showModal(contact)}
              key={index}
              isSelected={user.filter((chat) => chat.id === contact.id).length > 0}
              contact={contact}
            />
          )
        })}
      </div>
    </>
  )
}

export default memo(ForwardList)